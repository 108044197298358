import gql from 'graphql-tag'


export const LIST_ORDER_EXPENSE = (templateType) => gql`query LIST_ORDER_EXPENSE ($docType: String!) {
  items: listDoc${templateType}OrderExpense (docType: $docType) {
    id name isDefault
  }
}`

export const LIST_ORDER_VAT_PRICE = (templateType) => gql`query LIST_ORDER_VAT_PRICE ($docType: String!) {
  items: listDoc${templateType}OrderVatPrice (docType: $docType) {
    id name isDefault options
  }
}`

export const LIST_ORDER_WITHHOLDING_PRICE = (templateType) => gql`query LIST_ORDER_WITHHOLDING_PRICE ($docType: String!) {
  items: listDoc${templateType}OrderWithholdingPrice (docType: $docType) {
    id name isDefault
  }
}`

export const LIST_ORDER_PAYMENT = (templateType) => gql`query LIST_ORDER_PAYMENT ($docType: String!) {
  items: listDoc${templateType}OrderPayment (docType: $docType) {
    id name isDefault
  }
}`


export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name
    qty price totalPrice
    unitId unit {id name}
    docId doc {id code}
    ledgers {
      id docId stockId orderId amount
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
  }
}`


export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_VAT_PRICE = (templateType) => gql`mutation CREATE_ORDER_VAT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderVatPrice (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_WITHHOLDING_PRICE = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderWithholdingPrice (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`

export const CREATE_ORDER_PAYMENT = (templateType) => gql`mutation CREATE_ORDER_PAYMENT ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}OrderPayment (docType: $docType, docId: $docId, input: $input) {
    id
  }
}`


export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {
    id
  }
}`
